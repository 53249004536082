import * as React from 'react';

import { graphql, useStaticQuery } from 'gatsby';
import moment from 'moment';

import { dolCTAReferences } from '../constant';
import { AnimatedRow } from './DolRegister';
import AnimatedImageLayout from './DolRegister/AnimatedImageLayout';
import DolContact from './DolRegister/Contact';
import Title from './MainLayout/Title';

export const DolInfoAndScholarships = React.memo(() => {
  const result = useStaticQuery(graphql`
    {
      gcms {
        jsonContanier(where: { name: "online-scholarships" }) {
          content
        }
      }
    }
  `);

  const data = result.gcms.jsonContanier.content;

  const numberOfDates = moment(data.endDate).diff(
    moment(data.startDate),
    'days'
  );

  const numberOfRemainDates = moment(new Date()).diff(
    moment(data.startDate),
    'days'
  );

  const isLastDate = numberOfDates === 1;
  const averageScholarshipsPerDate = Math.round(
    data.scholarships / numberOfDates
  );
  const endDateFormatDM = moment(data.endDate).format('D/M');

  return (
    <AnimatedImageLayout>
      <AnimatedRow id={dolCTAReferences.REGISTER}>
        <DolContact
          Heading={() => (
            <Title style={{ maxWidth: '600px' }}>
              Liên hệ với DOL ngay để có cơ hội{' '}
              <strong>nhận học bổng {data.cash}</strong> khi đăng ký{' '}
              <strong>trước {endDateFormatDM}</strong>
            </Title>
          )}
          overrideDolInfo={{
            contactForm: {
              title: `Còn <span>${
                isLastDate
                  ? data.lastDayScholarships
                  : data.scholarships -
                    averageScholarshipsPerDate * numberOfRemainDates
              } suất</span> học bổng khi <span>đăng ký trước ${endDateFormatDM}</span>`,
              description: '',
              href: null
            }
          }}
          onInfoClick={() => {}}
        />
      </AnimatedRow>
    </AnimatedImageLayout>
  );
});

DolInfoAndScholarships.propTypes = {};
