import * as React from 'react';

import DolInfo from '../DolInfo';
import { DolContact } from './DolContact';

const DolContactLegacy = props => {
  return (
    <DolInfo>
      {({ dolInfo }) => {
        return <DolContact {...props} dolInfo={dolInfo} />;
      }}
    </DolInfo>
  );
};

export default React.memo(DolContactLegacy);
