import React from 'react';
import PropTypes from 'prop-types';

import { FBSubscribeButton } from './RegisterButton';

const RegisterInlineButton = ({ title }) => (
  <FBSubscribeButton
    id="btn-header-to-register"
    size="large"
    href="#section-register"
    style={{
      marginLeft: '1em',
      fontSize: '16px',
      fontWeight: '400'
    }}
  >
    {title || 'Đăng ký tư vấn'}
  </FBSubscribeButton>
);

RegisterInlineButton.propTypes = {
  title: PropTypes.string
};

RegisterInlineButton.defaultProps = {
  title: null
};

export default RegisterInlineButton;
