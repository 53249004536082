import React from 'react';
import PropTypes from 'prop-types';

import { graphql } from 'gatsby';

import { DolInfoAndScholarships } from 'shared/DolInfoAndScholarship';
import FacebookReviewPaging from 'shared/FacebookReviewPaging';
import HallOfFame from 'shared/HallOfFame';

import withI18next from '../../components/withI18next';
import DolBigCTA from '../../shared/DolBigCTA';
import MainLayout from '../../shared/MainLayout';
import Footer from '../../shared/Marketing/Footer';
import Header from '../../shared/Marketing/Header';
import MySEO from '../../shared/MySEO';
import HeaderSchedulePage from '../../shared/Header/HeaderSchedulePage';

class Landing15Page extends React.PureComponent {
  render() {
    // const { homePageDataList } = this.props.data.gcms;
    // this is the kind of data is always have only 1 record.
    // const homePageData = homePageDataList[0];

    return (
      <MainLayout
        showFooterRegister={false}
        noMenu
        noHeader
        noFooter
        hideSideCTA
        renderCustomHeader={() => (
          <HeaderSchedulePage hideMobileBottomNav noMenu />
        )}
      >
        <MySEO title="Bước 6: Khóa học online, trải nghiệm như offline" />
        <Header noMenu bannerLink="/online" />
        <DolBigCTA ctaOptions={{ href: '#section-register' }} />

        <FacebookReviewPaging orderByIsOnline />
        <HallOfFame />
        <DolInfoAndScholarships />
        <Footer />
      </MainLayout>
    );
  }
}

Landing15Page.propTypes = {
  data: PropTypes.shape({
    gcms: PropTypes.shape({
      homePageDataList: PropTypes.arrayOf(
        PropTypes.shape({
          seoData: PropTypes.shape({}).isRequired,
          registerBackgroundImg: PropTypes.shape({}).isRequired,
          linearThinkingVideoList: PropTypes.arrayOf(PropTypes.shape({}))
        })
      ).isRequired,
      recommendationList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
      reviewList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
      facebookReviewList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
      atwCourses: PropTypes.arrayOf(PropTypes.shape({})).isRequired
    })
  }),
  pageContext: PropTypes.shape({
    meta: PropTypes.string.isRequired
  }).isRequired
};

export default withI18next()(Landing15Page);

export const pageQuery = graphql`
  query {
    gcms {
      atwCourses(orderBy: order_ASC, stage: PUBLISHED) {
        stage
        updatedAt
        createdAt
        id
        name
        bigTitle
        order
        slug
        type
        detail1Title
        detail1Content
        detail2Title
        detail2Content
        detail3Title
        detail3Content
        detail4Title
        detail4Content
        intakePoint
        targetPoint
        registerBackgroundImg {
          stage
          updatedAt
          createdAt
          id
          handle
          fileName
          height
          width
          size
          mimeType
          altText
          title
        }
        seoData: atwSeo {
          id
          stage
          updatedAt
          createdAt
          title
          description
          type
          featureImg {
            stage
            updatedAt
            createdAt
            id
            handle
            fileName
            height
            width
            size
            mimeType
            altText
            title
          }
        }
        programList: atwCoursePrograms {
          stage
          updatedAt
          createdAt
          id
          name
          order
          item1Title
          item1Content {
            html
          }
          item2Title
          item3Title
          item4Title
          item2Content {
            html
          }
          item3Content {
            html
          }
          item4Content {
            html
          }
        }
        teacherList: atwCourseTeachers {
          stage
          updatedAt
          createdAt
          id
          name
          profilePicture {
            stage
            updatedAt
            createdAt
            id
            handle
            fileName
            height
            width
            size
            mimeType
            altText
            title
          }
          funnyIntro
          kolEmoji {
            stage
            updatedAt
            createdAt
            id
            handle
            fileName
            height
            width
            size
            mimeType
            altText
            title
          }
          highlight
          order
        }
        benefitList: atwCourseBenefits {
          stage
          updatedAt
          createdAt
          id
          order
          icon {
            stage
            updatedAt
            createdAt
            id
            handle
            fileName
            height
            width
            size
            mimeType
            altText
            title
          }
          description
        }
        caseStudyList: atwCourseCaseStudies {
          stage
          updatedAt
          createdAt
          id
          name
          slug
          title
          intakePoint
          intakeRef
          targetPoint
          targetRef
          intakeProofFile {
            stage
            updatedAt
            createdAt
            id
            handle
            fileName
            height
            width
            size
            mimeType
            altText
            title
          }
          targetProofFile {
            stage
            updatedAt
            createdAt
            id
            handle
            fileName
            height
            width
            size
            mimeType
            altText
            title
          }
          item1Title
          item2Title
          item3Title
          item4Title
          item1Content {
            html
          }
          item2Content {
            html
          }
          item3Content {
            html
          }
          item4Content {
            html
          }
          proofPic1 {
            stage
            updatedAt
            createdAt
            id
            handle
            fileName
            height
            width
            size
            mimeType
            altText
            title
          }
          proofPic2 {
            stage
            updatedAt
            createdAt
            id
            handle
            fileName
            height
            width
            size
            mimeType
            altText
            title
          }
          reviewVideo {
            stage
            updatedAt
            createdAt
            id
            handle
            fileName
            height
            width
            size
            mimeType
            altText
            title
          }
          reviewPoster {
            stage
            updatedAt
            createdAt
            id
            handle
            fileName
            height
            width
            size
            mimeType
            altText
            title
          }
          profilePicture {
            stage
            updatedAt
            createdAt
            id
            handle
            fileName
            height
            width
            size
            mimeType
            altText
            title
          }
          avatar {
            stage
            updatedAt
            createdAt
            id
            handle
            fileName
            height
            width
            size
            mimeType
            altText
            title
          }
          date
          overview {
            html
          }
        }
      }

      facebookReviewList: facebookReviews(orderBy: order_ASC) {
        reviewUrl
      }

      homePageDataList: atwPageHomes(stage: PUBLISHED, first: 1) {
        stage
        updatedAt
        createdAt
        id
        registerBackgroundImg {
          stage
          updatedAt
          createdAt
          id
          handle
          fileName
          height
          width
          size
          mimeType
          altText
          title
        }
        seoData: atwSeo {
          id
          stage
          updatedAt
          createdAt
          id
          title
          description
          type
          featureImg {
            id
            handle
            fileName
            height
            width
            size
            mimeType
            altText
            title
          }
        }
        linearThinkingVideoList: linearThinkingVideo(orderBy: order_ASC) {
          id
          stage
          name
          duration
          order
          startTime
          startTimeDisplay
          poster {
            stage
            id
            handle
            fileName
            height
            width
            size
            mimeType
            altText
            title
          }
          video {
            stage
            id
            handle
            fileName
            height
            width
            size
            mimeType
            altText
            title
          }
          videoIntro {
            stage
            id
            handle
            fileName
            height
            width
            size
            mimeType
            altText
            title
          }
        }
      }
      recommendationList: atwRecommendations(orderBy: order_ASC, first: 24) {
        stage
        updatedAt
        createdAt
        id
        type
        content {
          html
        }
        sourceName
        sourceUrl
        sourceLogo {
          stage
          updatedAt
          createdAt
          id
          handle
          fileName
          height
          width
          size
          mimeType
          altText
          title
        }
        order
        date
      }
    }
  }
`;
