import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { InView } from 'react-intersection-observer';

import OutlineArrowChevronDown from '@dolstaff/shared/es/icons/OutlineArrowChevronDown';
import OutlineArrowChevronUp from '@dolstaff/shared/es/icons/OutlineArrowChevronUp';
import PureCloseRed from '@dolstaff/shared/es/icons/PureCloseRed';
import PureDolRed from '@dolstaff/shared/es/icons/PureDolRed';
import PureMenuRed from '@dolstaff/shared/es/icons/PureMenuRed';
import cl from 'classnames';
import { Link } from 'gatsby';
import styled from 'styled-components';

import { Container } from '../../Blocks';
import MyButton from '../../MyButton';
import RegisterButton from '../../MyButton/RegisterButton';
import RegisterInlineButton from '../../MyButton/RegisterInlineButton';
import MenuPopover from './MenuPopover';

const Root = styled.header`
  position: fixed;
  background-color: ${p => p.bgColor || 'white'};
  padding: 15px 0;
  top: 0;
  z-index: 99;
  height: 72px;
  width: 100vw;
  transition: all 0.3s;

  &.active {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  }
`;

const Main = styled.div`
  position: relative;
  z-index: 99;

  .mainLeft {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
  }
  .mainRight {
    display: none;
    background-color: white;
    margin-left: -20px;
    margin-right: -20px;
    background-color: white;
  }
  .mainRightExpanded {
    display: inherit;
    position: absolute;
    left: 0;
    right: 0;
    padding-bottom: 2em;
    border-bottom: 1px solid whitesmoke;
    border-bottom-left-radius: 1em;
    border-bottom-right-radius: 1em;
  }
  .headerMenu {
    margin-bottom: 0;
    margin-right: -8px;
    padding: 0 8px;
    height: auto;
  }

  @media screen and (min-width: 1024px) {
    display: flex;
    justify-content: space-between;

    .headerMenu {
      display: none;
    }
    .mainRight {
      display: inherit;
      position: static;
      padding-bottom: 0;
      border-bottom: 0;
      margin-left: 0;
      margin-right: 0;
    }
  }
`;

const LogoWrp = styled(Link)`
  padding: 0.5em;
  margin: -0.5em;
`;

const MenuList = styled.ul`
  list-style: none;
  margin: 0;
  display: block;
  padding: 0;
  margin-top: 2em;

  .menuItem {
    margin: 0;
    display: block;
    margin-bottom: 0.5em;
  }

  @media screen and (min-width: 1024px) {
    display: inline-block;
    margin-top: 0;
    margin-left: 0;
    margin-right: 1em;

    .menuItem {
      display: inline-block;
      margin-bottom: 0;
    }
  }
`;

const MenuButton = styled(MyButton)`
  transition: all 0.1s;
  background-color: transparent;
  color: black;
  padding-left: 1em;
  padding-right: 1em;
  display: block;
  text-align: left;
  width: 100%;
  font-size: 16px;
  font-weight: 400;

  &:hover,
  &:focus,
  &:active {
    color: #d14242;
    background-color: transparent;
  }

  @media screen and (min-width: 1024px) {
    width: auto;
    font-size: 14px;
  }
  @media screen and (min-width: 1140px) {
    width: auto;
    font-size: 16px;
  }
`;

const MenuWrp = styled.div`
  border-radius: 4px;
  padding: 0.5em;

  @media screen and (min-width: 1024px) {
    border: 1px solid whitesmoke;
  }
`;
const MenuLink = styled(Link)`
  color: black;
  display: block;
  padding: 0.5em 1em;
  padding-left: 2em;
  margin-bottom: 4px;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 400;

  &:hover {
    background-color: whitesmoke;
  }

  @media screen and (min-width: 1024px) {
    padding-left: 1em;
  }
`;

const CloseButton = styled(MyButton)`
  svg {
    vertical-align: middle;
  }
`;

const Header = ({
  noMenu,
  noRegister,
  bannerLink = '/m/linearthinking',
  renderMenu = () => {},
  bgColor,
  containerStyle,
  registerBtnTitle
}) => {
  const [expanded, setExpanded] = useState(false);
  const [headerActive, setHeaderActive] = useState(false);

  return (
    <>
      <InView
        id="header-tracker"
        style={{
          position: 'absolute',
          right: '0',
          width: '0',
          height: '72px'
        }}
        onChange={inView => {
          setHeaderActive(!inView);
        }}
      />
      <Root bgColor={bgColor} className={cl({ active: headerActive })}>
        <Container style={containerStyle}>
          <Main>
            <div className="mainLeft">
              <LogoWrp to={bannerLink}>
                <PureDolRed width={103} height={40} />
              </LogoWrp>
              {!noMenu ? (
                <CloseButton
                  style={{
                    backgroundColor: 'transparent',
                    borderRadius: '4px'
                  }}
                  className="headerMenu"
                  href="#"
                  onClick={() => setExpanded(!expanded)}
                >
                  {!expanded ? (
                    <PureMenuRed size={30} />
                  ) : (
                    <PureCloseRed size={30} />
                  )}
                </CloseButton>
              ) : (
                !noRegister && <RegisterInlineButton title={registerBtnTitle} />
              )}
              {renderMenu()}
            </div>

            {!noMenu && (
              <div className={cl('mainRight', { mainRightExpanded: expanded })}>
                <MenuList>
                  <li className="menuItem">
                    <MenuPopover
                      position="bottom"
                      width="260px"
                      trigger={({ active, setActive }) => (
                        <MenuButton
                          to="/about-us/our-story"
                          onClick={() => setActive(!active)}
                        >
                          Về chúng tôi
                          {active ? (
                            <OutlineArrowChevronUp size={20} />
                          ) : (
                            <OutlineArrowChevronDown size={20} />
                          )}
                        </MenuButton>
                      )}
                    >
                      {() => (
                        <MenuWrp>
                          <MenuLink to="/about-us/our-story">
                            Câu chuyện của DOL
                          </MenuLink>
                        </MenuWrp>
                      )}
                    </MenuPopover>
                  </li>
                  <li className="menuItem">
                    <MenuButton to="/ielts">Các khoá IELTS</MenuButton>
                  </li>
                  <li className="menuItem">
                    <MenuPopover
                      position="bottom"
                      width="180px"
                      trigger={({ active, setActive }) => (
                        <MenuButton
                          to="/ielts-library"
                          onClick={() => setActive(!active)}
                        >
                          Thư viện
                          {active ? (
                            <OutlineArrowChevronUp size={20} />
                          ) : (
                            <OutlineArrowChevronDown size={20} />
                          )}
                        </MenuButton>
                      )}
                    >
                      {() => (
                        <MenuWrp>
                          <MenuLink to="/ielts-library/blogs">Blogs</MenuLink>
                          <MenuLink to="/ielts-library/videos">Videos</MenuLink>
                          <MenuLink to="/ielts-library/books">Books</MenuLink>
                          <MenuLink to="/ielts-library/samples">
                            Samples
                          </MenuLink>
                        </MenuWrp>
                      )}
                    </MenuPopover>
                  </li>
                  <li className="menuItem">
                    <MenuButton to="/ielts/review">Điểm & Review</MenuButton>
                  </li>
                  <li className="menuItem">
                    <MenuButton to="/ielts/schedule">Lịch học</MenuButton>
                  </li>
                  <li className="menuItem">
                    <MenuButton to="/contact">Liên hệ</MenuButton>
                  </li>
                </MenuList>
                <RegisterButton />
              </div>
            )}
          </Main>
        </Container>
      </Root>
    </>
  );
};

Header.propTypes = {
  noMenu: PropTypes.bool,
  noRegister: PropTypes.bool,
  bannerLink: PropTypes.string,
  renderMenu: PropTypes.func,
  bgColor: PropTypes.string,
  containerStyle: PropTypes.object,
  registerBtnTitle: PropTypes.string
};

export default Header;
