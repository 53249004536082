import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cl from 'classnames';

const Main = styled.div`
  position: relative;
  background-color: white;
  z-index: 99;

  .body {
    display: none;
    background-color: white;
    width: 100%;

    &.active {
      display: initial;
    }
  }

  @media screen and (min-width: 1024px) {
    display: inline-block;

    .trigger {
      display: inline-block;
    }

    .body {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 100%);
      width: 260px;
    }

    /* only for desktop */
    &:hover {
      .body {
        display: initial;
      }
    }
  }
`;

const MenuPopover = ({ trigger, children }) => {
  const [active, setActive] = useState(false);

  return (
    <Main>
      <div className="trigger">{trigger({ active, setActive })}</div>
      <div className={cl('body', { active })}>{children()}</div>
    </Main>
  );
};

MenuPopover.propTypes = {
  trigger: PropTypes.func.isRequired,
  children: PropTypes.func.isRequired
};

export default MenuPopover;
