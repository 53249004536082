import React, { memo, useCallback, useMemo, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { EmbeddedPost, FacebookProvider } from 'react-facebook';

import cl from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';

import { Container, Section } from 'components/withMarketingSection';
import { isBrowser } from 'utils';
import useMediaQuery from 'utils/react-responsive';

import c, { dolCTAReferences } from '../../constant';
import { H2 } from '../MainLayout/Title';
import WhiteButton from '../MyButton/WhiteButton';

const ITEM_PER_PAGE_DESKTOP = 6;
const ITEM_PER_PAGE_MOBILE = 1;
const reducer = (prevState, nextState) => ({ ...prevState, ...nextState });

const getSlice = (list, skip, take) => list.slice(skip, skip + take);

const FacebookReview = ({ title, orderByIsOnline }) => {
  const data = useStaticQuery(graphql`
    query {
      gcms {
        facebookReviews(
          where: { isValidLink: true }
          orderBy: postLinkCreatedAt_DESC
        ) {
          reviewUrl
        }
        orderByIsOnline: facebookReviews(orderBy: isOnline_DESC) {
          reviewUrl
        }
      }
    }
  `);
  const list = orderByIsOnline
    ? data.gcms.orderByIsOnline
    : data.gcms.facebookReviews;

  const { isTabletOrMobile } = useMediaQuery();
  const [paging, setPaging] = useReducer(reducer, {
    page: 0,
    skip: 0,
    size: isTabletOrMobile ? ITEM_PER_PAGE_MOBILE : ITEM_PER_PAGE_DESKTOP
  });
  const [fbReviews, setFbReviews] = useState({
    urls: getSlice(list, paging.skip, paging.size),
    preloadUrls: getSlice(list, paging.skip + paging.size, paging.size)
  });

  const facebookPluginWidth = useMemo(() => {
    const innerWidth = isBrowser() ? window.innerWidth : 1280;
    const em2 = 32; // 2em = 32; e = mc2
    if (innerWidth >= 1024) {
      return innerWidth / 2 - em2;
    }
    if (innerWidth >= 1280) {
      return innerWidth / 3 - em2;
    }
    return 350;
  }, []);

  const onNext = useCallback(() => {
    if (fbReviews.urls.length < list.length) {
      const nextPaging = { ...paging };
      nextPaging.skip += nextPaging.size;
      setPaging(nextPaging);
      setFbReviews(oldData => ({
        urls: oldData.urls.concat(oldData.preloadUrls),
        preloadUrls: getSlice(
          list,
          nextPaging.skip + nextPaging.size,
          nextPaging.size
        )
      }));
    }
  }, [fbReviews.urls.length, list, paging]);

  return (
    <Section
      selfish
      style={{ backgroundColor: '#FBFBFB' }}
      id={dolCTAReferences.REVIEW}
    >
      <Container>
        <Main>
          <div className="header">{title}</div>
          <ReviewList>
            {[...fbReviews.urls, ...fbReviews.preloadUrls].map(
              ({ reviewUrl }) => (
                <ReviewItem
                  className={cl({
                    'preload-url': fbReviews.preloadUrls.some(
                      url => url.reviewUrl === reviewUrl
                    )
                  })}
                  key={reviewUrl}
                >
                  <FacebookProvider appId={c.FACEBOOK_APP_ID}>
                    <EmbeddedPost
                      showText
                      href={reviewUrl}
                      width={`${facebookPluginWidth}px`}
                    />
                  </FacebookProvider>
                </ReviewItem>
              )
            )}
          </ReviewList>

          {fbReviews.urls.length < list.length ? (
            <div className="controlWrp">
              <WhiteButton onClick={onNext}>
                Xem thêm {list.length - fbReviews.urls.length}+ review
              </WhiteButton>
            </div>
          ) : null}
        </Main>
      </Container>
    </Section>
  );
};

const Main = styled.div`
  .header {
    margin-bottom: 1em;
  }

  .controlWrp {
    text-align: center;
    margin-top: 2em;
  }

  @media screen and (min-width: 1024px) {
    .header {
      margin-bottom: 3em;
    }
  }
`;

const ReviewList = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 2em;
  justify-content: center;

  @media screen and (min-width: 720px) {
    justify-content: flex-start;
    margin-left: -1em;
    margin-right: -1em;
  }
`;
const ReviewItem = styled.div`
  margin-bottom: 1em;
  width: 350px;

  &.preload-url {
    height: 0;
    padding: 0;
    overflow: hidden;
  }

  @media screen and (min-width: 720px) {
    width: calc(50% - 2em);
    margin-left: 1em;
    margin-right: 1em;
  }
  @media screen and (min-width: 1024px) {
    width: calc(50% - 2em);
  }
  @media screen and (min-width: 1280px) {
    width: calc(100% / 3 - 2em);
  }
`;

FacebookReview.propTypes = {
  title: PropTypes.node,
  orderByIsOnline: PropTypes.boolean
};

FacebookReview.defaultProps = {
  title: (
    <H2>
      Những review <b>“tám cây số”</b> của Dolbies
    </H2>
  ),
  orderByIsOnline: false
};

export default memo(FacebookReview);
